import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67e3b2d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contact-info" }
const _hoisted_2 = { class: "column" }
const _hoisted_3 = { class: "column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelledValue = _resolveComponent("LabelledValue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LabelledValue, {
        label: "address",
        value: _ctx.contact.address
      }, null, 8, ["value"]),
      _createVNode(_component_LabelledValue, {
        label: "email",
        value: _ctx.contact.email
      }, null, 8, ["value"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_LabelledValue, {
        label: "phone",
        value: _ctx.contact.phone
      }, null, 8, ["value"]),
      _createVNode(_component_LabelledValue, {
        label: "fax",
        value: _ctx.contact.fax
      }, null, 8, ["value"])
    ])
  ]))
}