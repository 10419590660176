import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48ffef4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button" }
const _hoisted_2 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: _ctx.values.url,
      target: _ctx.values.newTab ? '_blank' : ''
    }, _toDisplayString(_ctx.values.text), 9, _hoisted_2)
  ]))
}