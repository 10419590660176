import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-424c39c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dynamic-renderer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.components, (component) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getComponentByType(component.__component)), {
        key: component.id,
        values: component
      }, null, 8, ["values"]))
    }), 128))
  ]))
}