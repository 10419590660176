import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32aaccaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-group max-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_Selector = _resolveComponent("Selector")!
  const _component_DateSelector = _resolveComponent("DateSelector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attributes, (attribute) => {
      return (_openBlock(), _createElementBlock("div", {
        key: attribute.id
      }, [
        (attribute.type === 'string' && !attribute.values)
          ? (_openBlock(), _createBlock(_component_Search, {
              key: 0,
              onUpdate: (n) => _ctx.updateFilter(attribute.id.toString(), n)
            }, null, 8, ["onUpdate"]))
          : _createCommentVNode("", true),
        (attribute.values)
          ? (_openBlock(), _createBlock(_component_Selector, {
              key: 1,
              attribute: attribute,
              onUpdate: (n) => _ctx.updateFilter(attribute.id.toString(), n)
            }, null, 8, ["attribute", "onUpdate"]))
          : _createCommentVNode("", true),
        (attribute.type === 'date')
          ? (_openBlock(), _createBlock(_component_DateSelector, {
              key: 2,
              attribute: attribute,
              onDateUpdate: (n) => _ctx.updateFilter(attribute.id.toString(), n)
            }, null, 8, ["attribute", "onDateUpdate"]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}