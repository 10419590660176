import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7db3dcf0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoCardVariant1 = _resolveComponent("InfoCardVariant1")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card) => {
      return (_openBlock(), _createBlock(_component_InfoCardVariant1, {
        key: card?.title,
        class: "grid-item",
        values: {
        imgSrc: card?.imgSrc,
        imgAlt: card?.imgAlt,
        title: card?.title,
        description: card?.description,
        button: card?.button,
      }
      }, null, 8, ["values"]))
    }), 128))
  ]))
}