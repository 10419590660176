import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c6a2445e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "project" }
const _hoisted_2 = { class: "project-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericImage = _resolveComponent("GenericImage")!
  const _component_ContactInformation = _resolveComponent("ContactInformation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.project.attributes.image?.data?.id)
      ? (_openBlock(), _createBlock(_component_GenericImage, {
          key: 0,
          image: _ctx.project.attributes.image
        }, null, 8, ["image"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.project.attributes.title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.project.attributes.description), 1)
    ]),
    (_ctx.project.attributes.contact)
      ? (_openBlock(), _createBlock(_component_ContactInformation, {
          key: 1,
          contact: _ctx.project.attributes.contact
        }, null, 8, ["contact"]))
      : _createCommentVNode("", true)
  ]))
}