import Papa from "papaparse";

const data = `alterspyramideZum,einwohnerGesamt,mannlich,weiblich
31.12.2023,"16.431","7.858","8.573"
30.06.2023,"16.494","7.851","8.643"
31.12.2022,"16.576","7.874","8.702"
30.06.2022,"16.654","7.903","8.751"
31.12.2021,"16.598","7.926","8.672"
30.06.2021,"16.722","7.964","8.758"
31.12.2020,"16.883","8.053","8.830"
30.06.2020,"16.953","8.110","8.843"
31.12.2019,"17.000","8.118","8.882"
30.06.2019,"17.023","8.147","8.876"
31.12.2018,"17.135","8.184","8.951"
30.06.2018,"17.278","8.267","9.011"
31.12.2017,"17.398","8.325","9.073"
30.06.2017,"17.597","8.451","9.146"
31.12.2016,"17.697","8.517","9.180"
30.06.2016,"17.781","8.530","9.251"
31.12.2015,"17.939","8.616","9.323"
30.06.2015,"17.525","8.321","9.204"
31.12.2014,"17.614","8.361","9.253"
30.06.2014,"17.754","8.414","9.340"
31.12.2013,"17.848","8.456","9.392"
30.06.2013,"18.023","8.551","9.472"
31.12.2012,"18.166","8.621","9.545"
30.06.2012,"18.371","8.743","9.628"
31.12.2011,"18.553","8.843","9.710"
30.06.2011,"18.842","8.994","9.848"
31.12.2010,"18.957","9.054","9.903"
30.06.2010,"19.156","9.165","9.991"
31.12.2009,"19.294","9.209","10.085"
30.06.2009,"19.525","9.321","10.204"
31.12.2008,"19.701","9.394","10.307"
30.06.2008,"19.991","9.529","10.462"
31.12.2007,"20.091","9.590","10.501"
30.06.2007,"20.386","9.723","10.663"
31.12.2006,"20.568","9.807","10.761"
30.06.2006,"20.777","9.898","10.879"
31.12.2005,"21.089","10.117","10.972"
30.06.2005,"21.386","10.258","11.128"
31.01.2005,"21.535","10.350","11.185"`;

export interface einwohnerzahlType {
  alterspyramideZum: string;
  einwohnerGesamt: number;
  mannlich: number;
  weiblich: number;
}

export const einwohnerzahl: einwohnerzahlType[] = [];

Papa.parse(data, {
  header: true,
  dynamicTyping: true,
  complete: (results) => {
    results.data.forEach((row: any) => {
      einwohnerzahl.push({
        alterspyramideZum: row.alterspyramideZum,
        einwohnerGesamt: row.einwohnerGesamt,
        mannlich: row.mannlich,
        weiblich: row.weiblich,
      });
    });
  },
  error: (err: any) => {
    console.error(err);
  },
});
