import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-459cb948"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "events" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_eventCard = _resolveComponent("eventCard")!
  const _component_paginationComponent = _resolveComponent("paginationComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.events, (event) => {
        return (_openBlock(), _createBlock(_component_eventCard, {
          key: event.id,
          event: event
        }, null, 8, ["event"]))
      }), 128))
    ]),
    _createVNode(_component_paginationComponent, { onUpdatePagination: _ctx.handlePaginationUpdate }, null, 8, ["onUpdatePagination"])
  ], 64))
}