import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52ac5bea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-content" }
const _hoisted_2 = ["alt", "src"]
const _hoisted_3 = {
  key: 1,
  class: "title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueMarkdown = _resolveComponent("VueMarkdown")!
  const _component_GenericButton = _resolveComponent("GenericButton")!
  const _component_CardComponent = _resolveComponent("CardComponent")!

  return (_openBlock(), _createBlock(_component_CardComponent, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.values.imgSrc)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "image",
              alt: _ctx.values.imgAlt,
              src: _ctx.values.imgSrc
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.values.title)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.values.title), 1))
          : _createCommentVNode("", true),
        (_ctx.values.description)
          ? (_openBlock(), _createBlock(_component_VueMarkdown, {
              key: 2,
              class: "description",
              options: _ctx.markdownOptions,
              source: _ctx.values.description
            }, null, 8, ["options", "source"]))
          : _createCommentVNode("", true),
        (_ctx.values.button)
          ? (_openBlock(), _createBlock(_component_GenericButton, {
              key: 3,
              values: _ctx.values.button
            }, null, 8, ["values"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}